.wrapper video {
  width: 100%;
  transition: width 1s;
}

.fullscreen {
  opacity: 0;
  transition: opacity 1s;
}

.fullscreen.revealed {
  opacity: 1;
}
